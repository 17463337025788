@import '../core/index';

[class*="weather-icon--"] {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: toRem(20);
  max-width: toRem(20);
  width: toRem(20);

  @media (media(m-min)) {
    height: toRem(32);
    max-width: toRem(32);
    width: toRem(32);
  }
}

$icons: 116;

/* stylelint-disable indentation */
@for $i from 65 through $icons {
  // Excludes icon codes which refer to the same icon
  // Mapping duplicate icon codes to a single icon code is handled with the mapIconCodes function in client/src/components/weather/withWeatherData/index.js
  @if $i != 86 {
    .weather-icon--#{$i} {
      background-image: url('../../images/icons/weather/svg/#{$i}.svg');
    }
  }
}
/* stylelint enable */
